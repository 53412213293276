import { AbstractControl, ValidationErrors } from '@angular/forms';

export function atLeastOneCheckValidator(siteParam: string): ValidationErrors | null {
  return (control: AbstractControl): ValidationErrors | null => {
    const checked = Object.values(control.value).some(value => !!value);

    if (!checked && siteParam != 'cccommunity' && siteParam != 'xecosystem') {
      Object.keys(control.value).forEach(key => control.get(key)?.setErrors({ atLeastOneRequired: true }));
      return { atLeastOneRequired: true };
    } else {
      Object.keys(control.value).forEach(key => control.get(key)?.setErrors(null));
    }

    return null;
  };
}
