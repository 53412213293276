<div class="card">
    <div class="card-body" *ngIf="showSuccessMessage">
        <div class="row mb-3">
            <div class="col-8 m-auto">
                <div class="row">
                    <p>
                        {{'registration.success' | translate}}
                    </p>
                    <div class="col-12 text-center">
                        <i class="success-icon element-checked text-primary"></i>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="card-body" *ngIf="!showSuccessMessage">
        <div class="row mb-3" *ngIf="messageResponse">
            <div #errorDiv class="col-8 m-auto error-msg">
                <p>
                    {{messageResponse}}
                </p>
            </div>
        </div>
        <div #mainDiv class="row mb-3">
            <div class="col-8 m-auto">
                <div class="row">
                    <h3 *ngIf="!siteParam">{{'title.default' | translate}}</h3>
                    <h3 *ngIf="siteParam === 'oem'">{{'title.oem' | translate}}</h3>
                    <h3 *ngIf="siteParam === 'cccommunity'">{{'title.cccommunity' | translate}}</h3>
                    <h3 *ngIf="siteParam === 'xecosystem'">{{'title.xecosystem' | translate}}</h3>

                    <p *ngIf="!siteParam">
                        {{'site.param.text.default.1' | translate}}
                        <span [innerHTML]="'site.param.url.default' | translate"></span>
                        {{'site.param.text.default.2' | translate}}
                    </p>
                    <p *ngIf="siteParam === 'oem'">
                        {{'site.param.text.oem.1' | translate}}
                        <span [innerHTML]="'site.param.url.oem' | translate"></span>
                        {{'site.param.text.oem.2' | translate}}
                    </p>
                    <p *ngIf="siteParam === 'cccommunity'">
                        {{'site.param.text.cccommunity' | translate}}
                    </p>
                    <p *ngIf="siteParam === 'xecosystem'">
                        {{'site.param.text.xecosystem.1' | translate}}
                        <br>
                        {{'site.param.text.xecosystem.2' | translate}}
                    </p>
                </div>
            </div>
        </div>



        <form [formGroup]="form" #f="ngForm" (ngSubmit)="submit()">
            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="firstNameInput" formControlName="firstName"
                                autocomplete="off" placeholder="{{'first.name' | translate}}"
                                [ngClass]="validate('firstName')">
                            <label for="firstNameInput">* {{'first.name' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.FIRST_NAME_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="lastNameInput" formControlName="lastName"
                                autocomplete="off" placeholder="{{'last.name' | translate}}"
                                [ngClass]="validate('lastName')">
                            <label for="lastNameInput">* {{'last.name' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.LAST_NAME_REQUIRED' | translate}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="companyInput" formControlName="company"
                                autocomplete="off" placeholder="{{'company' | translate}}"
                                [ngClass]="validate('company')">
                            <label for="companyInput">* {{'company' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.COMPANY_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="emailInput" formControlName="email"
                                autocomplete="off" placeholder="{{'company.email' | translate}}"
                                [ngClass]="validate('email')">
                            <label for="emailInput">* {{'company.email' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">
                                <span *ngIf="form.controls.email.errors?.alreadyExist">
                                    {{'FORM.EMAIL_EXISTS' | translate}}
                                </span>
                                <span *ngIf="form.controls.email.errors?.required || form.controls.email.errors?.email ">
                                    {{'FORM.EMAIL_REQUIRED' | translate}}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating">
                            <select type="text" class="form-select sie-floating-select text-white" id="countryInput"
                                (ngModelChange)="countryChanged($event)" formControlName="country"
                                [ngClass]="validate('country')">
                                <option *ngFor="let item of countriesOptions" [value]="item.id">
                                    {{ item.title | translate }}
                                </option>
                            </select>
                            <label for="countryInput">* {{'country' | translate}}</label>
                            <i class="invalid-icon element-cancel-filled"></i>
                            <div class="invalid-feedback">{{'FORM.COUNTRY_REQUIRED' | translate}}</div>
                        </div>

                        <div class="col-6 form-floating sie-floating-input" *ngIf="!profileFound">
                            <input type="text" class="form-control" id="cityInput" formControlName="city"
                                autocomplete="off" placeholder="{{'city' | translate}}">
                            <label for="cityInput">{{'city' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="streetInput" formControlName="street"
                                autocomplete="off" placeholder="{{'street' | translate}}">
                            <label for="streetInput">{{'street' | translate}}</label>
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="zipInput" formControlName="zip"
                                autocomplete="off" placeholder="{{'zip' | translate}}">
                            <label for="zipInput">{{'zip' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6 form-floating sie-floating-input">
                            <ngx-intl-tel-input [cssClass]="'form-control text-white form select sie-floating-select'"
                                [preferredCountries]="[]" #ngxIntlTelInput [enableAutoCountrySelect]="false"
                                [enablePlaceholder]="true" [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false" [maxLength]="15" [separateDialCode]="separateDialCode"
                                [phoneValidation]="true" name="phoneInput" formControlName="phoneObj"
                                [ngClass]="validate('phoneObj')"></ngx-intl-tel-input>
                            <div class="invalid-feedback">{{'FORM.INVALID_PHONE' | translate}} </div>
                            <!-- <pre>{{ form.controls['phoneObj'].errors | json }}</pre> -->
                        </div>

                        <div class="col-6 form-floating sie-floating-input">
                            <input type="text" class="form-control" id="departmentInput" formControlName="department"
                                autocomplete="off" placeholder="{{'department' | translate}}">
                            <label for="departmentInput">{{'department' | translate}}</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3" *ngIf="!profileFound">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-6">
                                <div class="form-floating sie-floating-input input-group">
                        
                                    <input type="password" class="form-control custom-focus" id="passwordInput" formControlName="password"
                                        placeholder="{{'password' | translate}}" [ngClass]="validate('password')">
                                    <button class="input-group-addon custom-focus" type="button" (click)="changePasswordType('passwordInput')">
                                        <svg role="img" name="hide" class="password-icon">
                                            <use xlink:href="assets/svg/symbol-defs.svg#sie-Hide"></use>
                                        </svg>
                                        <svg role="img" name="show" class="password-icon" style="display: none;">
                                            <use xlink:href="assets/svg/symbol-defs.svg#sie-Show"></use>
                                        </svg>
                                    </button>
                        
                        
                                    <label for="passwordInput">* {{'password' | translate}}</label>
                                    <i class="invalid-icon element-cancel-filled"></i>
                                    <div class="invalid-feedback">
                                        <span *ngIf="!form.get('password')?.value">{{'FORM.PASSWORD_REQUIRED' |
                                            translate}}</span>
                                        <span *ngIf="form.get('password')?.value">{{'FORM.PASSWORD_STRENGTH' |
                                            translate}}</span>
                                    </div>
                                </div>
                                
                        
                        </div>

                        <div class="col-6">
                            <div class="form-floating sie-floating-input input-group">

                                <input type="password" class="form-control custom-focus" id="retypePasswordInput" formControlName="retypePassword"
                                    placeholder="{{'confirm.password' | translate}}" [ngClass]="validate('retypePassword')">
                                <button class="input-group-addon custom-focus" type="button" (click)="changePasswordType('retypePasswordInput')">
                                    <svg role="img" name="hide" class="password-icon">
                                        <use xlink:href="assets/svg/symbol-defs.svg#sie-Hide"></use>
                                    </svg>
                                    <svg role="img" name="show" class="password-icon" style="display: none;">
                                        <use xlink:href="assets/svg/symbol-defs.svg#sie-Show"></use>
                                    </svg>
                                </button>
                                
                                <label for="retypePasswordInput">* {{'confirm.password' | translate}}</label>
                                <i class="invalid-icon element-cancel-filled"></i>
                                <div class="invalid-feedback">
                                    <span *ngIf="!form.get('retypePassword')?.value">{{'FORM.RETYPE_PASSWORD_REQUIRED' |
                                        translate}}</span>
                                    <span *ngIf="form.get('retypePassword')?.value">{{'FORM.RETYPE_PASSWORD_NO_MATCH' |
                                        translate}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row info-label">
                        <p>{{'required.fields' | translate}}</p>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <p>{{'password.warning' | translate}}</p>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <hr class="hr" />
                    </div>
                </div>
            </div>


            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input"
                            *ngIf="siteParam !== 'cccommunity' && siteParam !== 'xecosystem'" formGroupName="portalSection">
                            <p class="mb-3">{{'portal.section.message' | translate}}</p>

                            <div class="form-check" *ngIf="!siteParam">
                                <input id="buildingAutomationInput" class="form-check-input"
                                    formControlName="buildingAutomation" type="checkbox"
                                    [ngClass]="validate('portalSection')" />
                                <label for="buildingAutomationInput" class="form-check-label"> Building
                                    Automation </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="!siteParam">
                                <input id="fireSafetyInput" class="form-check-input" formControlName="fireSafety"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="fireSafetyInput" class="form-check-label"> Fire Safety </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                <input id="climatixOemInput" class="form-check-input" formControlName="climatixOem"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="climatixOemInput" class="form-check-label"> Climatix OEM </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>

                            <div class="form-check" *ngIf="siteParam === 'oem'">
                                <input id="combustionInput" class="form-check-input" formControlName="combustion"
                                    type="checkbox" [ngClass]="validate('portalSection')" />
                                <label for="combustionInput" class="form-check-label"> Combustion </label>
                                <i class="invalid-icon element-cancel-filled"></i>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input"
                            *ngIf="siteParam === 'cccommunity' || siteParam === 'xecosystem'" formGroupName="portalSection">
                        </div>

                        <div class="col-lg-6 col-md-12 col-sm-12 form-floating sie-floating-input">
                            <div class="form-control-has-icon w-100">
                                <re-captcha #reCaptcha formControlName="recaptcha" class="form-control captcha-form" theme="dark"
                                    [ngClass]="validate('recaptcha')"></re-captcha>
                                <i class="invalid-icon element-cancel-filled"></i>
                                <div class="invalid-feedback p-l-25">{{'FORM.RECAPTCHA_REQUIRED' | translate}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row mb-3">
                <div class="col-8 m-auto">
                    <div class="row">
                        <!-- <div class="col-8"></div> -->
                        <div class="col-12 m-auto div-align-right">
                            <button class="sie-button sie-button-secondary" type="reset">{{'clear' |
                                translate}}</button>
                            <button class="sie-button sie-button-primary mx-3" type="submit">{{'submit' |
                                translate}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>