import { Component, OnInit } from '@angular/core';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import * as bootstrap from 'bootstrap';
import { environment } from 'src/environments/environment';
import { AuthService } from '../shared/services/auth.service';
import { getLocalItem, setLocalItem } from '../shared/utils/local-storage-service';

@Component({
  selector: 'app-header-new',
  templateUrl: './header-new.component.html',
  styleUrls: ['./header-new.component.scss']
})
export class HeaderNewComponent implements OnInit {
  profileFound = false;
  userLang: string;
  hintText = '';
  toolTip: bootstrap.Tooltip;

  constructor(private translate: TranslateService, private authService: AuthService) {
    const language = getLocalItem('userLangPreference') || 'en';
    this.userLang = language;
    translate.use(language);
  }

  ngOnInit(): void {
    this.getProfile();
    console.log('Header ngOnInit');

    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      console.log(event.lang);

      const exampleTriggerEl = document.getElementById('infoSvg');
      const tooltip = bootstrap.Tooltip.getInstance(exampleTriggerEl!);
      tooltip?.dispose();

      const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
      const tooltipList = tooltipTriggerList.map(tooltipTriggerEl => {
        const elem = new bootstrap.Tooltip(tooltipTriggerEl, {
          title: this.translate.instant('sign.in.hint')
        });
        return elem;
      });

      setTimeout(() => {
        const event = new Event('mouseover');
        const elem = document.getElementById('infoSvg');

        elem?.dispatchEvent(event);
      }, 300);
    });
  }

  changeLanguage($event: string) {
    this.userLang = $event;
    this.translate.use(this.userLang).subscribe(() => {
      setLocalItem('userLangPreference', this.userLang);
    });
  }

  signIn() {
    open(`${environment.serverUrl}/auth`, '_self');
  }

  getProfile(): void {
    this.authService.getProfile().subscribe({
      next: response => {
        // console.log(`Profile response: ${JSON.stringify(response)}`);

        if (response?.id) {
          this.profileFound = true;
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }
}
