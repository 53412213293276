import { AfterContentChecked, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { RecaptchaComponent } from 'ng-recaptcha';
import { CountryISO, NgxIntlTelInputComponent, SearchCountryField } from 'ngx-intl-tel-input-gg';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import { RegistrationService } from '../registration/registration.service';
import { Profile } from '../shared/interfaces/auth';
import { countries } from '../shared/interfaces/countries';
import { EventTypes } from '../shared/interfaces/event-types';
import { ConfirmModalService } from '../shared/modals/confirm-modal/confirm-modal.service';
import { AuthService } from '../shared/services/auth.service';
import { ToastService } from '../shared/services/toast.service';
import { FormUtil } from '../shared/utils/form.util';
import { atLeastOneCheckValidator } from '../shared/validators/at-least-one-checked.validator';
import { passwordMatchValidator } from '../shared/validators/password.validator';

@Component({
  selector: 'app-registration-new',
  templateUrl: './registration-new.component.html',
  styleUrls: ['./registration-new.component.scss']
})
export class RegistrationNewComponent implements OnInit, AfterContentChecked {
  @ViewChild('ngxIntlTelInput') ngxIntlTelInputRef!: NgxIntlTelInputComponent;
  @ViewChild('reCaptcha') reCaptcha!: RecaptchaComponent;

  form: UntypedFormGroup;
  countriesOptions: { id: string; title: string }[] = [];
  countryMap: any;
  countryKeys: string[] = [];

  showSuccessMessage = false;

  messageResponse = '';

  protected showPassword = false;
  protected showRetypePassword = false;

  siteParam: any;

  profileFound = false;
  profile: Profile = {} as Profile;

  strongPasswordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%*-])(?!.*[.'"&<>/|=^])(?!.*(.)\1{2}).{12,50}$/;

  SearchCountryField = SearchCountryField;
  CountryISO = CountryISO;
  separateDialCode = true;

  activeSites = 'oem;cccommunity;xecosystem;';

  constructor(
    formBuilder: UntypedFormBuilder,
    private registrationService: RegistrationService,
    private translate: TranslateService,
    private toastService: ToastService,
    private spinner: NgxSpinnerService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private authService: AuthService,
    private confirmModalService: ConfirmModalService
  ) {
    this.form = formBuilder.group(
      {
        firstName: [null, Validators.required],
        lastName: [null, Validators.required],
        company: [null, Validators.required],
        department: null,
        street: null,
        zip: null,
        city: null,
        phone: null,
        country: [null, Validators.required],
        email: [null, [Validators.required, Validators.email]],
        password: [null, [Validators.required, Validators.pattern(this.strongPasswordRegex)]],
        retypePassword: [null, Validators.required],
        portalSection: formBuilder.group(
          {
            buildingAutomation: null,
            fireSafety: null,
            climatixOem: null,
            combustion: null
          }
          // { validators: atLeastOneCheckValidator(this.siteParam) }
        ),
        recaptcha: [null, Validators.required],
        subsite: null,
        phoneObj: null,
        existingUser: false
      },
      { validators: passwordMatchValidator }
    );

    this.route.queryParamMap.subscribe((p: any) => {
      const params = p.params;
      this.siteParam = [params.SubSite, params.subsite, params.Subsite, params.subSite].find(s => s);

      const currentUrl = window.location.href.toLowerCase();
      if (!this.siteParam && currentUrl.includes('subsite=')) {
        this.siteParam = currentUrl.substring(currentUrl.indexOf('=') + 1, currentUrl.indexOf('#'));
      }
      if (!this.activeSites.includes(this.siteParam + ';')) {
        this.siteParam = undefined;
      }

      this.form.patchValue({ subsite: this.siteParam?.toLowerCase() });
      this.form.get('portalSection')?.clearValidators();
      this.form.get('portalSection')?.addValidators(atLeastOneCheckValidator(this.siteParam) as ValidatorFn);
    });
  }

  ngOnInit(): void {
    this.countriesOptions = countries;
    this.countryMap = new Map(this.countriesOptions.map(element => [element.id, element.title]));
    this.getProfile();
  }

  removeValidators(form: UntypedFormGroup) {
    for (const key in form.controls) {
      form.get(key)?.clearValidators();
      form.get(key)?.updateValueAndValidity();
    }
  }

  updateFormValidationStatus(form: UntypedFormGroup) {
    for (const key in form.controls) {
      form.get(key)?.updateValueAndValidity();
    }
  }

  showLoginConfirmation() {
    this.confirmModalService
      .open({
        modalId: 'loginConfirm',
        title: this.translate.instant('Existing User'),
        text: this.translate.instant('Already have an account?'),
        confirmButtonTitle: this.translate.instant('login'),
        hasCancelButton: true
      })
      .then(isConfirmed => {
        if (isConfirmed) {
          console.log('making redirect');
          open(`${environment.serverUrl}/auth`, '_self');
        }
      });
  }

  getProfile(): void {
    this.authService.getProfile().subscribe({
      next: response => {
        // console.log(`Profile response: ${JSON.stringify(response)}`);
        this.profile = response;

        if (this.profile?.id) {
          this.profileFound = true;
          const country_found = this.countryMap.has(this.profile.country);

          this.form.patchValue({
            firstName: this.profile.name.givenName,
            lastName: this.profile.name.familyName,
            email: this.profile.emails[0].value,
            company: this.profile.company,
            existingUser: true
          });
          this.removeValidators(this.form);
          this.form.get('firstName')?.disable();
          this.form.get('lastName')?.disable();
          this.form.get('email')?.disable();
          if (this.profile.company) {
            this.form.get('company')?.disable();
          } else {
            this.form.get('company')?.addValidators(Validators.required);
          }
          if (country_found) {
            this.form.patchValue({
              country: this.profile.country
            });
            this.form.get('country')?.disable();
          } else {
            this.form.get('country')?.addValidators(Validators.required);
          }
          this.form.get('recaptcha')?.addValidators(Validators.required);
          this.form.get('portalSection')?.addValidators(atLeastOneCheckValidator(this.siteParam) as ValidatorFn);
          this.updateFormValidationStatus(this.form);
        } else {
          // this.showLoginConfirmation();
        }
      },
      error: err => {
        console.error(err);
      }
    });
  }

  ngAfterContentChecked() {
    this.cdr.detectChanges();
  }

  getErrors(controlName: string): ValidationErrors | null | undefined {
    return this.form.get(controlName)?.errors;
  }

  objectValueProvider(object: { value: string; name: string }) {
    return object.name;
  }

  objectKeyProvider(object: { value: string; name: string }) {
    return object.value;
  }

  changePasswordType(elementId:string): void {
    const input = document.getElementById(elementId) as HTMLInputElement;
    const password = 'password';
    const text = 'text';

    if (input.type === password) {
      input.type = text;

      Array.from(input.nextElementSibling?.children!).forEach(element => {
        if(element.getAttribute("name") ==="show"){
          (element as HTMLElement).style.display = "inline";
        }else if(element.getAttribute("name") ==="hide"){
          (element as HTMLElement).style.display = "none";
        }
      });

    } else {
      input.type = password;

      Array.from(input.nextElementSibling?.children!).forEach(element => {
        if(element.getAttribute("name") ==="show"){
          (element as HTMLElement).style.display = "none";
        }else if(element.getAttribute("name") ==="hide"){
          (element as HTMLElement).style.display = "inline";
        }
      });
    }
  }

  submit(): void {
    this.messageResponse = '';

    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.spinner.show();

      const copyForm = {
        ...this.form.getRawValue(),
        country: {
          value: this.form.getRawValue().country.toLowerCase(),
          name: this.countryMap.get(this.form.getRawValue().country)
        },
        phone: this.form.getRawValue().phoneObj?.internationalNumber
      };

      this.registrationService.register(copyForm).subscribe({
        next: response => {
          this.spinner.hide();

          if (response.userCreated) {
            this.showSuccessMessage = true;
            this.messageResponse = this.translate.instant('registration.success');
            this.toastService.showToast('Finished', this.translate.instant('registration.success.notification'), EventTypes.Success, true);
          } else {
            this.messageResponse = this.translate.instant('registration.already.exists');
            this.toastService.showToast('Warning', this.translate.instant('registration.already.exists'), EventTypes.Warning, true);

            // Scroll Top
            const languageBox = document.getElementsByClassName('root').item(0);
            languageBox?.scrollIntoView({ behavior: 'smooth' });

            // Invalidate email field
            this.form.get('email')?.setErrors({ alreadyExist: true });

            // Reset Recaptcha
            this.reCaptcha.reset();
          }
        },
        error: err => {
          this.spinner.hide();
          // this.toastService.showToast('Error', 'An error occurred while registering.', EventTypes.Error, true);
        }
      });
    }
  }

  trackByIndex = (index: number): number => index;

  get language() {
    return this.translate.currentLang;
  }

  validate(control: string): { [key: string]: boolean } {
    return FormUtil.validate(this.form, control);
  }

  countryChanged($event: any) {
    if (!this.ngxIntlTelInputRef) {
      return;
    }
    const allCountries = this.ngxIntlTelInputRef.allCountries;
    const found = allCountries.find(c => c.iso2.toUpperCase() === $event);
    if (found) {
      this.ngxIntlTelInputRef.setSelectedCountry(found);
    }
  }
}
